<template>
	<section id="cover" class="relative flex flex-col-reverse lg:flex-col">
		<div
			data-aos="fade-left"
			data-aos-delay="400"
			class="bg-stone inset-y-0 right-0 top-0 z-0 mx-auto w-full max-w-xl md:px-0 lg:absolute lg:mx-0 lg:mb-0 lg:w-7/12 lg:max-w-full lg:pr-0 xl:px-0"
		>
			<svg
				class="absolute left-0 hidden h-full -translate-x-1/2 transform text-white dark:text-black lg:block"
				viewBox="0 0 100 100"
				fill="currentColor"
				preserveAspectRatio="none slice"
			>
				<path d="M50 0H100L50 100H0L50 0Z" />
			</svg>

			<img
				title="cover image"
				alt="cover image"
				aria-label="cover image"
				loading="eager"
				width="600"
				height="224"
				class="h-56 w-full object-top shadow-lg md:h-96 lg:h-full lg:shadow-none"
				src="/images/img13.webp"
			/>
		</div>
		<div class="relative flex w-full flex-col items-start">
			<div class="mb-20 lg:my-24 lg:max-w-lg lg:pr-5">
				<div data-aos="fade-right" class="mb-4">
					<span class="rounded-lg bg-gray-900 px-3 py-1.5 font-semibold text-white">Hair Stylist</span>
				</div>
				<h1
					data-aos-delay="100"
					data-aos="fade-right"
					class="mb-5 font-sans text-3xl font-bold tracking-tight sm:text-4xl sm:leading-none"
				>
					Salvo Beato Moda &amp; Stile Parrucchieri a
					<span class="text-red-700">Paternò</span>
				</h1>
				<span data-aos-delay="200" data-aos="fade-right" class="mb-5 block pr-5 text-base md:text-lg">
					<p class="font-light">
						Mi presento, sono
						<strong>Salvo Beato</strong>
						, il fondatore del marchio
						<strong>Moda&amp;Stile Parrucchieri</strong>
						. Da oltre vent'anni mi occupo di questa professione, iniziando a soli tredici anni e
						approfondendo le mie competenze attraverso corsi di specializzazione e certificazioni
						internazionali, tra cui quella rilasciata dall'Accademia Britannica HABIA.
					</p>
				</span>
				<div data-aos="fade-right" data-aos-delay="300" class="flex flex-wrap items-center gap-2 font-semibold">
					<NuxtLink
						title="Contatti"
						to="#contact"
						class="group flex w-full justify-between rounded-md border border-black-900 px-6 py-3 text-black-900 xs:w-auto"
					>
						Contatti

						<Icon name="solar:arrow-right-outline" class="ml-2 h-6 w-6 transition-all group-hover:ml-4" />
					</NuxtLink>
					<NuxtLink
						title="Contatti"
						to="#contact"
						class="w-full rounded-md bg-gray-900 px-6 py-3 text-gray-50 transition-colors hover:bg-gray-800 xs:w-auto"
					>
						<Icon name="solar:calendar-outline" class="mr-2 h-6 w-6" />
						Prenota ora
					</NuxtLink>
				</div>
			</div>
		</div>
	</section>
</template>
