<template>
	<div>
		<SchemaOrgWebPage name="" image="/images/logo-bianco-261w.webp" />

		<BasePage>
			<HomeCover />
		</BasePage>
		<HomeDescription />

		<main>
			<BasePage>
				<LazyHomeProducts />
				<LazyHomeServices />
				<LazyHomeBlog />
				<LazyHomeGallery />
				<LazyHomeStatistics />
				<LazyHomeFaq />
				<LazyHomeContact />
			</BasePage>
		</main>

		<UModal v-model="isOpen">
			<UCard>
				<img src="/images/ferie.webp" alt="Salvo Beato Moda & Stile" />
			</UCard>
		</UModal>
	</div>
</template>

<script setup lang="ts">
const isOpen = ref(true);
useSeoMeta({
	title: 'Salvo Beato Moda & Stile - Parrucchiere a Paternò',
	ogTitle: 'Salvo Beato Moda & Stile - Parrucchiere a Paternò',
	ogUrl: '/',
	twitterTitle: 'Salvo Beato Moda & Stile - Parrucchiere a Paternò',
	twitterDescription: 'Salvo Beato Moda & Stile - Parrucchiere a Paternò',
	twitterImage: '/images/logo-bianco-261w.webp',
	description:
		'Cerchi un parrucchiere per uomo e donna a Paternò? Prenota ora presso il salone di Salvo Beato specializzato in tagli moda, acconciature e barbering.',
	ogDescription:
		'Cerchi un parrucchiere per uomo e donna a Paternò? Prenota ora presso il salone di Salvo Beato specializzato in tagli moda, acconciature e barbering.',
	ogImage: '/images/logo-bianco-261w.webp',
	twitterCard: 'summary'
});

useHead({
	htmlAttrs: {lang: 'it'},
	link: [
		{rel: 'icon', type: 'image/x-icon', href: '/favicon.ico'},
		{
			rel: 'apple-touch-icon',
			type: 'image/x-icon',
			sizes: '180x180',
			href: '/images/icon/apple-touch-icon.png'
		},
		{
			rel: 'icon',
			type: 'image/png',
			sizes: '32x32',
			href: '/images/icon/favicon-32x32.png'
		},
		{
			rel: 'icon',
			type: 'image/png',
			sizes: '16x16',
			href: '/images/icon/favicon-16x16.png'
		},
		{rel: 'manifest', href: '/site.webmanifest'}
	]
});
</script>
