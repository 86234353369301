<template>
	<section id="whoami" class="mb-20 bg-stone-900 py-20 text-gray-50 lg:mb-24 lg:py-24">
		<div
			class="mx-auto grid items-center justify-center gap-12 px-4 font-normal md:container lg:grid-cols-2 xl:px-8"
		>
			<div>
				<NuxtLink
					title="logo image go to home page"
					aria-label="logo image go to home page"
					class="flex gap-4 text-3xl"
					to="/"
				>
					<img
						class="mx-auto mb-12"
						loading="eager"
						src="/images/logo-bianco-261w.webp"
						sizes="90px lg:120px"
						title="logo image"
						alt="logo image"
						aria-label="logo image"
					/>
				</NuxtLink>

				<h2 class="mb-8 text-center text-4xl">Moda&amp;Stile Parrucchieri di Salvo Beato</h2>
				<p>
					Il marchio
					<b>Moda&Stile Parrucchieri</b>
					è stato fondato da me,
					<b>Salvo Beato</b>
					, nel 2011, ma la mia passione per il mondo dell'hair styling è iniziata molto prima, nel 2002,
					quando ho intrapreso questa carriera giovanissimo, a soli tredici anni. Da allora, non ho mai smesso
					di crescere e imparare, arricchendo le mie competenze con corsi di specializzazione e certificazioni
					internazionali, tra cui l'Accademia Britannica HABIA.
				</p>
				<p class="mt-2">
					Ho inoltre seguito master di specializzazione in comunicazione e marketing con Dale Carnegie,
					imparando dai migliori esperti del settore come Sergio Borra, e ho frequentato numerosi corsi di
					formazione per affinare costantemente le mie abilità.
				</p>
				<p class="mt-2">
					Tutto questo impegno mi ha permesso di realizzare il mio sogno nel 2011, quando ho inaugurato il
					primo salone Moda&Stile Parrucchieri. Da allora, il nostro marchio è diventato sinonimo di
					professionalità e competenza nel settore dell'hair styling, rappresentando un punto di riferimento
					per chi cerca eccellenza e innovazione.
				</p>
			</div>
			<div class="hidden flex-wrap gap-6 lg:flex">
				<img
					class="max-h-[900px] w-full rounded-lg shadow-2xl"
					src="/images/img11.webp"
					alt="Salvo Beato Moda & Stile Parrucchieri"
					aria-label="Salvo Beato Moda & Stile Parrucchieri"
					title="Salvo Beato Moda & Stile Parrucchieri"
				/>
			</div>
		</div>
	</section>
</template>
